<script setup lang="ts">
const DELAY_FOR_GLOBAL_LISTENERS = 300

const model = defineModel({ type: Boolean, default: false })

let setTimeoutId: NodeJS.Timeout
watch(model, () => {
  off()
  clearTimeout(setTimeoutId)
  setTimeoutId = setTimeout(onShow, DELAY_FOR_GLOBAL_LISTENERS)
})

function onToogle() {
  if (!model.value) return
  model.value = false
  off()
}

function onShow() {
  if (!model.value) return off()
  document.body.addEventListener('click', onToogle)
}

function off() {
  document.body.removeEventListener('click', onToogle)
}

onBeforeUnmount(off)
</script>

<template>
  <transition name="fade">
    <div
      v-if="model"
      class="border-1 header-dropdown rounded-6 bg-gray-200 box-border border-solid p-8"
      @click.stop="void 0"
    >
      <slot />
    </div>
  </transition>
</template>

<style scoped>
.header-dropdown {
  border-color: #efefef;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
</style>
