<script setup lang="ts">
import { ButtonGreen, ButtonRed } from '@/shared/ui/button'

const props = defineProps({
  name: {
    type: String,
    default: 'ButtonGreen',
  },
})

const componantName = computed(() => {
  return {
    ButtonGreen,
    ButtonRed,
  }[props.name]
})
</script>

<template>
  <component :is="componantName" class="rounded-8 text-h7-semibold flex-wrap px-12" :size="'32'">
    <slot />
  </component>
</template>
