<template>
  <button
    class="bg-black-900 border-color rounded-10 flex h-32 items-center justify-center border-2 border-solid px-8"
    style="border-color: #fff3"
  >
    <span class="text-h7-semibold button-text">
      {{ $t('shared.premium') }}
    </span>
  </button>
</template>

<style scoped>
.button-text {
  background: linear-gradient(180deg, #f8d248 3.13%, #eb742f 96.87%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
