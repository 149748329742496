<template>
  <button
    data-test="widget_header__button_premium"
    class="button-background rounded-10 flex h-32 items-center justify-center border-2 border-solid px-8 text-white"
    style="
      background: linear-gradient(0deg, #06d672 4.69%, #12f588 93.75%);
      border-color: rgba(255, 255, 255, 0.3);
    "
    name="button-red"
  >
    <span class="text-h7-semibold">
      {{ $t('shared.premium') }}
    </span>
  </button>
</template>
