<script setup lang="ts">
defineProps({
  to: {
    type: String,
    required: true,
  },
  inCall: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['exit-call'])

const localePath = useLocalePath()
</script>

<template>
  <div class="rounded-12 relative bg-white">
    <NuxtLink
      class="text-h6-semibold min-w-44 group relative flex h-full cursor-pointer items-center justify-center text-center text-gray-800 transition-all hover:text-black lg:mx-16 lg:w-auto lg:items-center lg:pb-0"
      :to="localePath(to)"
      exact-active-class="!text-blupur"
    >
      <slot />
      <div
        class="nav-line bg-blupur invisible absolute bottom-0 left-0 h-2 w-full rounded-full lg:group-hover:fill-black"
      />
    </NuxtLink>
    <div
      v-if="inCall"
      class="absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
      @click="emit('exit-call')"
    />
  </div>
</template>

<style scoped>
.router-link-active .nav-line {
  visibility: visible !important;
}

:deep(.router-link-active svg) {
  @apply !fill-blupur;
}
</style>
