<script setup lang="ts">
import IconPlus from '@/shared/assets/icons/IconPlus.svg'

import { userEntity } from '@/entities/user'

import CabHeaderButton from './CabHeaderButton.vue'
import CabHeaderButtonPremium from './CabHeaderButtonPremium.vue'
import CabHeaderButtonPremium4 from './CabHeaderButtonPremium4.vue'
import CabHeaderCoinsDropdown from './CabHeaderCoinsDropdown.vue'
import CabHeaderDropdown from './CabHeaderDropdown.vue'

defineProps({
  coins: {
    type: Number,
    default: 0,
  },
  inCall: {
    type: Boolean,
    default: false,
  },
  showLowCoins: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['buy'])

const modalShow = ref(false)
const isSubscriber = computed(() => userEntity.model.isSubscriber())
const isPatron = computed(() => userEntity.model.isPatron())
function onToogle() {
  modalShow.value = !modalShow.value
}

function onBuy() {
  modalShow.value = false
  emit('buy')
}

function onBuyBaseCoins() {
  const isFirstPayment = !userEntity.model.isPatron()
  !isFirstPayment ? onToogle() : onBuy()
}
</script>

<template>
  <div class="relative flex items-center">
    <CabHeaderButtonPremium v-if="!isSubscriber && !isPatron" @click="onBuyBaseCoins" />
    <CabHeaderButton
      v-if="(isSubscriber || isPatron) && showLowCoins"
      name="ButtonRed"
      class="min-w-88 ml-4"
      @click="onBuy()"
    >
      {{ $t('shared.low_coins') }}
    </CabHeaderButton>
    <CabHeaderButton
      v-if="(isSubscriber || isPatron) && inCall"
      class="max-w-44 ml-4 flex h-32 w-32 items-center justify-center p-0"
      @click="onToogle()"
    >
      <IconPlus fill="white" />
    </CabHeaderButton>
    <CabHeaderButtonPremium4 v-if="(isSubscriber || isPatron) && !inCall" @click="onToogle()" />
    <CabHeaderDropdown v-model="modalShow" class="absolute right-0 top-full z-30">
      <CabHeaderCoinsDropdown :coins="coins" @buy="onBuy()" />
    </CabHeaderDropdown>
  </div>
</template>
