<script setup lang="ts">
import { ROUTE_NAV } from '@/shared/config'
import IconMiliLogo from '@/shared/public/IconMiliLogo.svg'

import { chatEntity } from '@/entities/chat'
import { userEntity } from '@/entities/user'
import { videoCallEntity } from '@/entities/video-call'

import { videoCallFeature } from '@/features/video-call'

import CabHeaderCoins from './CabHeaderCoins.vue'
import CabHeaderNavMessages from './CabHeaderNavMessages.vue'
import CabHeaderNavTopProfiles from './CabHeaderNavTopProfiles.vue'
import CabHeaderNavVideoChat from './CabHeaderNavVideoChat.vue'
import CabHeaderProfile from './CabHeaderProfile.vue'

const router = useRouter()
const localePath = useLocalePath()
const $modal = useModal()
const { $analytics } = useNuxtApp()
const userStore = userEntity.model.useUserStore()
const chatStore = chatEntity.model.useChatStore()

const state = reactive({
  goPath: '',
})
const avatarPath = computed(() => userStore.me?.avatar?.src)
const isOrganic = computed(() => !userEntity.model.isSuspected() && userEntity.model.isOrganic())
const isTopProfilesShow = computed(
  () =>
    userEntity.model.isPaid() ||
    userEntity.model.isPatron() ||
    isOrganic.value ||
    userEntity.model.isModerator(),
)
const unreadMessagesCount = computed(
  () => chatStore.unreadMessagesCount + chatStore.friendRequestsCount,
)
const isProfileShow = computed(() => {
  if (isCallActive.value) return false
  return true
})
const coins = computed(() => userStore.balance)
const showLowCoins = computed(
  () =>
    isCallActive.value &&
    userEntity.model.isPatron() &&
    !userEntity.model.balanceEnough(videoCallEntity.config.LOW_COINS),
)

const { isCallActive } = videoCallFeature.model.useStatus()

onMounted(() => {
  $modal.onConfirm('video-call-go', callbackGo)
})

onBeforeUnmount(() => {
  $modal.off('video-call-go', 'confirm')
})

function onExitCall(to: string) {
  state.goPath = to
  $modal.open('video-call-go')
}

function onBuy() {
  $analytics.event('main_screen_pressed', {
    button: 'coins',
  })
  $modal.open('payment', { entry: 'main' })
}

function callbackGo() {
  if (!state.goPath) return
  router.push(localePath(state.goPath))
  state.goPath = ''
}
</script>

<template>
  <header
    class="flex h-52 shrink-0 grow-0 select-none items-stretch justify-between border-b border-solid border-gray-200 bg-white pr-12 lg:h-48 lg:pl-12 lg:pr-16"
  >
    <nav class="relative flex w-1/2 shrink grow">
      <CabHeaderNavVideoChat
        :to="localePath(ROUTE_NAV.cabMain)"
        :in-call="isCallActive"
        @exit-call="onExitCall(ROUTE_NAV.cabMain)"
      />
      <CabHeaderNavTopProfiles
        v-if="isTopProfilesShow"
        :to="localePath(ROUTE_NAV.cabTopProfiles)"
        :in-call="isCallActive"
        data-product-tour="nav-top-profiles-target"
        @exit-call="onExitCall(ROUTE_NAV.cabTopProfiles)"
      />
      <CabHeaderNavMessages
        :to="localePath(ROUTE_NAV.cabChat)"
        :count="unreadMessagesCount"
        :in-call="isCallActive"
        @exit-call="onExitCall(ROUTE_NAV.cabChat)"
      />
    </nav>
    <div class="flex shrink-0 grow-0 justify-center items-center min-w-80 lg:hidden">
      <IconMiliLogo class="max-h-36" />
    </div>
    <div class="flex w-1/2 shrink grow justify-end">
      <CabHeaderCoins
        class="mr-12 last:mr-0"
        :coins="coins"
        :in-call="isCallActive"
        :show-low-coins="showLowCoins"
        @buy="onBuy()"
        @exit-call="onExitCall(ROUTE_NAV.cabChat)"
      />
      <CabHeaderProfile
        v-if="isProfileShow && !showLowCoins"
        :to="localePath(ROUTE_NAV.cabProfile)"
        :avatar-path="avatarPath"
        @exit-call="onExitCall(ROUTE_NAV.cabProfile)"
      />
    </div>
  </header>
</template>
