<script setup lang="ts">
import { UserAvatar } from '@/shared/ui/user-avatar'

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  gender: {
    type: String as () => 'male' | 'female' | '',
    default: '',
  },
  avatarPath: {
    type: String,
    default: '',
    required: false,
  },
})

const route = useRoute()
const localePath = useLocalePath()
const isActive = computed(() => localePath(route.path) === localePath(props.to))
</script>

<template>
  <div class="relative">
    <NuxtLink
      :to="localePath(to)"
      class="flex h-full cursor-pointer items-center"
      data-test="widget_header__button_profile"
      :class="{
        'before:bg-blupur before:absolute before:bottom-0 before:h-2 before:w-full': isActive,
      }"
    >
      <UserAvatar size="s" :src="avatarPath" :hover="true" :active="isActive" />
    </NuxtLink>
  </div>
</template>
