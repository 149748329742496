<script setup lang="ts">
import { ROUTE_NAV } from '@/shared/config'
import IconMiliLogo from '@/shared/public/IconMiliLogo.svg'
import { LoaderMain } from '@/shared/ui/loaders'

import { appEntity } from '@/entities/app'

import { CabHeader, CabHeaderSkeleton } from '@/widgets/cab-header'
import { FooterWithLinks } from '@/widgets/footer'
import { Preloader } from '@/widgets/preloader'

const { isDesktop } = useLayout()
const route = useRoute()
const localePath = useLocalePath()
const appStore = appEntity.model.useAppStore()
const loader = computed(() => appStore.loader)
const ready = computed(() => appStore.ready)
const showHeader = computed(
  () => localePath(route.path) !== (localePath(ROUTE_NAV.cabStories)),
)
</script>

<template>
  <div>
    <Preloader />
    <client-only>
      <div class="lg:bg-cabinet h-100-cvh flex flex-col">
        <div
          v-if="isDesktop"
          class="mb-16 hidden h-full max-h-80 w-full items-center justify-center lg:mb-8 lg:flex lg:max-h-60"
        >
          <IconMiliLogo class="my-12" />
        </div>

        <div
          class="lg:rounded-16 lg:min-w-960 lg:max-w-960 lg:min-h-664 lg:max-h-664 lg:container-shadow relative mx-auto flex h-full w-full flex-grow flex-col overflow-hidden lg:bg-white"
        >
          <CabHeader v-if="showHeader && ready" />
          <CabHeaderSkeleton v-if="showHeader && !ready" />
          <slot />
          <LoaderMain v-if="loader" :full="false" background="shadow" class="z-10" />
        </div>

        <FooterWithLinks v-if="isDesktop" class="mt-72 hidden lg:block" :show-title="true" />
      </div>
    </client-only>
  </div>
</template>

<style scoped>
.container-shadow {
  box-shadow: 0 8px 24px 0 rgba(227, 227, 227, 0.4);
}
</style>
